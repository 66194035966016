// Things related to Uploads page
const Uploads = {};

// Total number of files being sent to the cloud
// Includes files being uploaded or processed
// in some way before or after the upload
// It is the total number of all files
// in all uploads. Each upload can contain
// more than one file so if there are
// e.g. 2 uploads going on at the same
// time and they have 2 and 5 files
// then this number should be 7
Uploads.totalNrOfFilesBeingSent = 0;

// Number of uploading tasks that are processing
// or waiting to be processed
Uploads.nrOfOngoingUploadTasks = 0;

// This is set to true while data is being encrypted or sent to s3.
// Only one such process is allowed at a time.
// Not sure if there would be performance benefits of doing multiple uploads
// at the same time since we are already using multipart upload,
// but there might be benefits in doing the encryption part
// for multiple upload tasks at the same time, so that's something
// to consider in the future.
Uploads.isUploading = false;

Uploads.updateUploadingMessages = function(error)
{
	updateUploadingMessages(error, Uploads.totalNrOfFilesBeingSent, Uploads.nrOfOngoingUploadTasks);
};

// Filters and sorts the entries for the upload page
Uploads.filterAndSortEntries = function(entries)
{
	// We only want to show entries going into Arkio on the upload page
	// That is entries with ArkioImport
	const filterMethod = function(entry)
	{
		if (entry.entryValue.File != null)
		{
			return entry.entryValue.File.ArkioImport != null;
		}
		return false;
	};
	entries = entries.filter(filterMethod);
	entries = Cloud.sortEntriesByDate(entries);
	return entries;
};

Uploads.onRemoved = function (indexFile)
{
	Uploads.fillTableWithFilteredAndSortedEntries(indexFile);
};

Uploads.fillUploadTableWithEntries = function(entries) {
	let tableBody = document.getElementById('UploadsTableBody');
	fillTableWithEntries(tableBody, entries, false, true, true, showErrorMessage, showWarningMessage, Uploads.onRemoved);
};

// Fills the table on the upload page with filtered and sorted entries from index file
Uploads.fillTableWithFilteredAndSortedEntries = function(indexFile) {
	try {
		let entries = indexFile.GetEntriesAsArray();
		entries = Uploads.filterAndSortEntries(entries);
		Uploads.fillUploadTableWithEntries(entries);
	}
	catch (error) {
		console.error("Failure when trying to fill table: ", error);
		showErrorMessage("An unexpected error occurred while gathering data!");
	}
};

Uploads.fillTable = async function() {
	try {
		let indexFile = await getIndexFile(showErrorMessage);
		if (indexFile != null) {
			Uploads.fillTableWithFilteredAndSortedEntries(indexFile);
		}
	}
	catch (error) {
		console.error(error);
		showErrorMessage("An unexpected error occurred while gathering data!");
	}
};

// Update UI of Uploads page
Uploads.updateUI = function() { Uploads.fillTable(); };

// Get some info about files that are uploaded
// Returns object with the following members
// mainFile : Main file being uploaded, e.g. in case of a model
// the .obj file
// icon: string that can be Models or Images that indicates
// the resource type
// zipFile : Name of a zip file if found
Uploads.getFileDataInfo = function(filenames) {
	var info = {};
	var modelMainFile = "";
	var imageMainFile = "";
	info.zipFile = "";

	//Supported files: JPG, PNG, OBJ, GLB, GLTF, ZIP
	var n = filenames.length;
	var i;
	for(i = 0; i < n; i++)
	{
		var name = filenames[i];

		// If there are are more than one .glb .gltf or .obj file then the first file
		// found will be considered the "main" file.
		// Same for image files.
		// An image file will only be considered the main file if there was no
		// .glb .gltf or .obj file uploaded.

		if (name.endsWith(".zip"))
		{
			info.zipFile = name;
			break;
		}

		if (name.endsWith(".glb") || name.endsWith(".gltf") || name.endsWith(".obj"))
		{
			modelMainFile = name;
		}
		if (imageMainFile === "")
		{
			if (name.endsWith(".jpg") || name.endsWith(".jpeg") || name.endsWith(".png"))
			{
				imageMainFile = name;
			}
		}
	}

	if (info.zipFile === "")
	{
		if (modelMainFile !== "")
		{
			info.mainFile = modelMainFile;
			info.icon = Constants.Icon.Models;
		}
		else if (imageMainFile !== "")
		{
			info.mainFile = imageMainFile;
			info.icon = Constants.Icon.Images;
		}
		else
		{
			info.icon = "";
		}
	}
	return info;
};

Uploads.uploadToCloud_nativeEncryptStreamTest = async () => {
	if (!isLinked())
	{
		goToGroupPage();
		return;
	}

	let selectedFiles = document.getElementById('fileInput').files;
	let file = selectedFiles[0];
	let fileAsStream = file.stream();

	let encryptionKey = retrieveEncryptionKey();
	console.log('encryption key: ' + encryptionKey);
	let hash = CryptoUtil.createRandomHash();
	console.log('hash: ' + hash);

	console.log('Encrypting stream');
	let encryptedStream = await CryptoUtil.nativeEncryptStream(window.crypto.subtle, encryptionKey, hash, fileAsStream);
	console.log('Got encrypted stream. Converting it to buffer');
	let encryptedStreamAsBuffer = await DataUtil.streamToBuffer(encryptedStream);
	console.log(encryptedStreamAsBuffer);

	saveBufferToDisk(encryptedStreamAsBuffer, "data");
}

// Example showing how to do encryption with Web Crypto API
Uploads.uploadToCloud_NativeCryptoTest = async () => {

	if (!isLinked())
	{
		goToGroupPage();
		return;
	}

	let selectedFiles = document.getElementById('fileInput').files;
	let file = selectedFiles[0];
	let fileAsStream = file.stream();

	let bytes = await DataUtil.streamToBuffer(fileAsStream);
	console.log('bytes');
	console.log(bytes);

	let encryptionKey = retrieveEncryptionKey();
	let hash = CryptoUtil.createRandomHash();
	
	console.log('Using native encryption');
	let encrypted = await CryptoUtil.nativeEncrypt(window.crypto.subtle, encryptionKey, hash, bytes);
	encrypted = new Uint8Array(encrypted);
	console.log(encrypted);

	let stream2 = file.stream();
	console.log('Encrypting stream')
	let encryptedStream = await CryptoUtil.nativeEncryptStream(window.crypto.subtle, encryptionKey, hash, stream2);
	console.log('Got encrypted stream. Converting it to buffer');
	let encryptedStreamAsBuffer = await DataUtil.streamToBuffer(encryptedStream);
	console.log(encryptedStreamAsBuffer);

	saveBufferToDisk(encrypted, "data");

	console.log('Using native decryption');
	let decrypted = await CryptoUtil.nativeDecrypt(window.crypto.subtle, encryptionKey, hash, encrypted);
	decrypted = new Uint8Array(decrypted);
	console.log(decrypted);

	console.log('encrypting using crypto js');
	let encrypted2 = CryptoUtil.encryptObjectData(encryptionKey, hash, bytes);
	console.log(encrypted2);
};

// Example showing how to use TransformStream
// TODO remove this 
Uploads.uploadToCloud_TransformStreamTest = async () => {

	if (!isLinked())
	{
		goToGroupPage();
		return;
	}

	let selectedFiles = document.getElementById('fileInput').files;
	let file = selectedFiles[0];
	let fileAsStream = file.stream();

	console.log('creating TransformStream');
	const uppercaseTransform = new TransformStream({
		async transform(chunk, controller) {
			console.log('calling transform');
			const text = new TextDecoder().decode(chunk);
			const uppercasedText = text.toUpperCase();
			controller.enqueue(new TextEncoder().encode(uppercasedText));
		}
	});
	
	console.log('calling pipeThrough');
	let uppercaseStream = fileAsStream.pipeThrough(uppercaseTransform);

	console.log('calling streamToBuffer');
	let buffer = await DataUtil.streamToBuffer(uppercaseStream);
	console.log(buffer);
	const textOutput = new TextDecoder().decode(buffer);
	console.log(textOutput);
};

Uploads.uploadToCloud = async () => {

	if (!isLinked())
	{
		goToGroupPage();
		return;
	}

	var selectedFiles = document.getElementById('fileInput').files;
	var n = selectedFiles.length;
	let nrOfFiles = n;

	// Value that was added to Uploads.totalNrOfFilesBeingSent
	// in case it needs to be removed
	let nrOfFilesAddedInThisTask = 0;
	if (n < 1)
	{
		showErrorMessage("No files selected!");
		return;
	}

	try
	{
		Uploads.nrOfOngoingUploadTasks += 1;
		Uploads.updateUploadingMessages(false);

		console.log("Reading " + n + " files...");
		
		// Get the sum of the size of the files
		var totalBytes = 0; 
		for (var i = 0; i < n; i++)
		{
			totalBytes += selectedFiles[i].size;
		}

		let maxBytes = 2000 * 1000 * 1000;
		if (totalBytes > maxBytes) // We don't allow uploads greater than 2 GB
		{
			Uploads.nrOfOngoingUploadTasks -= 1;
			Uploads.updateUploadingMessages(true);
			console.warn('Size of files greater than ' + maxBytes);
			showErrorMessage("Size of upload too large! Must not exceed 2 GB.");
			return;
		}

		maxBytes = 500 * 1000 * 1000;
		if (totalBytes > maxBytes) // Very large uploads may result in failure
		{
			console.error('Size of files greater than ' + maxBytes);
			showWarningMessage("Files are larger than 500 MB, upload might fail.");
		}

		let filenames = [];
		for (let i = 0; i < selectedFiles.length; i++)
		{
			filenames.push(selectedFiles[i].name);
		}

		// Get type of resource and main file
		let fileInfo = Uploads.getFileDataInfo(filenames);

		let infoFileWithoutExt = "info";
		let arkioCloudExt = ".arkiocloud";

		// TODO add some info in here to be used in Arkio
		let cloudInfo = JSON.stringify({
			//message : "hello"
		});

		let icon = null; // Icon for cloud entry, inferred from the uploaded files
		var zipUInt8arr = null;
		if (fileInfo.zipFile !== '')
		{
			try
			{
				let n = selectedFiles.length;
				for (let i = 0; i < n; i++)
				{
					if (selectedFiles[i].name === fileInfo.zipFile)
					{
						// Load the zip archive
						let zipArchive = await DataUtil.loadZipArchive(selectedFiles[i]);

						// Get the name of the files in the zip archive
						filenames = Object.keys(zipArchive.files);
						nrOfFiles = filenames.length;
						fileInfo = Uploads.getFileDataInfo(filenames);
						if (fileInfo.zipFile !== '')
						{
							// We don't support zip files inside zip files
							// If we encounter this, we reject the upload
							Uploads.nrOfOngoingUploadTasks -= 1;
							Uploads.updateUploadingMessages(true);
							let msg = "It's not supported to upload a zip file inside a zip file!";
							console.error(msg);
							showErrorMessage(msg);
							return;
						}
						if (fileInfo.mainFile != null && fileInfo.mainFile !== "")
						{
							infoFileWithoutExt = Util.getFilenameWithoutExtension(fileInfo.mainFile);
						}
						let cloudInfoFileName = infoFileWithoutExt + arkioCloudExt;

						//Adding .arkiocloud file inside the zip file
						let zipContent = await DataUtil.addFileToZip(zipArchive, cloudInfoFileName, cloudInfo);

						icon = await DataUtil.inferIconFromZip(zipContent);
						zipUInt8arr = new Uint8Array(zipContent);
						break;
					}
				}
				fileInfo = Uploads.getFileDataInfo(filenames);
			}
			catch(err)
			{
				Uploads.nrOfOngoingUploadTasks -= 1;
				Uploads.updateUploadingMessages(true);
				let msg = "Failed when reading zip file!";
				console.error(msg + ": ", err);
				showErrorMessage(msg);
				return;
			}
		}
		else
		{
			if (fileInfo.mainFile != null && fileInfo.mainFile !== "")
			{
				infoFileWithoutExt = Util.getFilenameWithoutExtension(fileInfo.mainFile);
			}
			// .arkiocloud file
			let cloudInfoFileName = infoFileWithoutExt + arkioCloudExt;

			icon = DataUtil.inferIconFromFiles(selectedFiles);
			
			// If no zip file was uploaded then we need to zip the files
			// that got uploaded
			try
			{
				console.log("Compressing files...");
				// Create a zip archive
				// Also adds the .arkiocloud file as an extra file in the archive
				zipUInt8arr = await DataUtil.zipFiles(selectedFiles, cloudInfoFileName, cloudInfo);
				const zipFileSize = zipUInt8arr.byteLength;
				console.log('Done zipping files. Size of zip is ' + zipFileSize);
			}
			catch(err)
			{
				Uploads.nrOfOngoingUploadTasks -= 1;
				Uploads.updateUploadingMessages(true);
				let msg = "Failed to compress files!";
				console.error(msg + ": ", err);
				showErrorMessage(msg);
				return;
			}
		}

		nrOfFilesAddedInThisTask = nrOfFiles;
		Uploads.totalNrOfFilesBeingSent += nrOfFilesAddedInThisTask;
		Uploads.updateUploadingMessages(false);

		if (icon != null)
		{
			fileInfo.icon = icon;
		}

		console.log("Icon: " + fileInfo.icon);
		console.log("Resource main file: " + fileInfo.mainFile);
		
		if (fileInfo.icon == '')
		{
			Uploads.totalNrOfFilesBeingSent -= nrOfFiles;
			Uploads.nrOfOngoingUploadTasks -= 1;
			Uploads.updateUploadingMessages(true);
			showErrorMessage("Invalid file type!");
			return;
		}

		// Create hash of the zipped file
		var hash = CryptoUtil.createHash(zipUInt8arr);
		console.log('File hash: ' + hash);

		var encrkey = retrieveEncryptionKey();

		// Wait for other uploads to finish
		// before starting this one
		while (Uploads.isUploading)
		{
			await Util.sleep(200);
		}
		Uploads.isUploading = true;

		var putRes = await Cloud.putObject(encrkey, hash, fileInfo, nrOfFiles, zipUInt8arr, function (message) {
			console.log(message);
		});

		if (putRes.success)
		{
			console.log("Done uploading.");
			Uploads.totalNrOfFilesBeingSent -= nrOfFiles;
			Uploads.nrOfOngoingUploadTasks -= 1;
			Uploads.updateUploadingMessages(false);
			let indexFile = putRes.indexFile;
			Uploads.fillTableWithFilteredAndSortedEntries(indexFile);
		}
		else
		{
			Uploads.totalNrOfFilesBeingSent -= nrOfFiles;
			Uploads.nrOfOngoingUploadTasks -= 1;
			Uploads.updateUploadingMessages(true);
			showErrorMessage("Upload to cloud failed.");
		}
		Uploads.isUploading = false;
	} catch (e) {

		// Subtracting nrOfFilesAddedInThisTask from totalNrOfFilesBeingSent.
		// It could be 0 or more depending on where in the code the exception happened
		Uploads.totalNrOfFilesBeingSent -= nrOfFilesAddedInThisTask;
		Uploads.nrOfOngoingUploadTasks -= 1;
		Uploads.updateUploadingMessages(true);
		showErrorMessage("Encountered an unexpected error during upload.");
	}
};

Uploads.bodyOnLoad = function()
{
	let linked = isLinked();
	if (!linked)
	{
		// Redirect to manage group page if not connected to group
		goToGroupPage();
	}

	addGroupIdToTabLinksIfNeeded();
	Uploads.updateUI();
};
